
// css

// js
window.$ = window.jQuery  = require('jquery');

window.TomSelect = require('tom-select/dist/js/tom-select.complete.min.js');

// alpinejs
const Alpine   = require('alpinejs').default;
const ajax     = require('@imacrayon/alpine-ajax').default;
const collapse = require('@alpinejs/collapse').default;
window.Alpine = Alpine;
Alpine.plugin(ajax);
Alpine.plugin(collapse);
Alpine.start();